import React from "react"
import ltc from "../images/gallery-images/p-5-2.jpg"
import Reviews from "../components/reviews"

const EventSidebar = () => {
  return (
    <>
      <div class="xs:hidden sm:hidden md:hidden rounded overflow-hidden max-w-full col-span-2 xs:col-span-5 sm:col-span-5 md:col-span-5 xs:mx-4 sm:mx-4 md:mx-4 lg:mr-4 xl:mr-4 my-4">
        <div class="flex-shrink-0">
          <img
            class="h-64 w-full object-cover"
            src={ltc}
            alt="License to Carry (LTC) course in Bryan, College Station Texas. Brazos County"
          />
        </div>
        <div class="bg-white shadow-md rounded-b py-4 px-6">
          <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
            $65/person
          </span>
          <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
            Texas LTC Course (Client Favorite)
          </h3>
          <p class="mt-3 text-base leading-6 text-gray-500">
            We offer the most inclusive Texas License to Carry course in the
            Brazos Valley and meet all state regulations to train you in
            carrying a handgun.
          </p>
          <p class="mt-3 text-base leading-6 text-gray-500">
            <strong>Women’s Class</strong>: We are proud to exclusively host the
            first women’s only LTC course in the Brazos Valley. Join us to meet
            fellow female shooters in your area!
          </p>
          <p class="mt-3 text-base leading-6 text-gray-500">
            <strong>Couples’ Class</strong>: Are you looking for something fun
            and exciting to do with your honey this weekend? Join us for our
            Couples’ LTC class!
          </p>

          <div class="mt-8 flex items-center flex-row">
            <a
              href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
              class="w-36 text-center px-3 py-2 border shadow
                    border-transparent text-base font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </a>

            <a
              href="/texas-license-to-carry-ltc-course"
              class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:border-blue-300 transition duration-150 ease-in-out"
            >
              Learn more
            </a>
          </div>
        </div>
        <Reviews />
      </div>
    </>
  )
}
export default EventSidebar
