import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import EventSidebar from "../components/eventSidebar"
import MobileReviews from "../components/mobileReviews"
const IndexPage = () => (
  <Layout>
    <SEO title="Savage Outdoor & Defense | Firearms Training Bryan, College Station, TX" />
    <div class="grid grid-cols-5 grid-flow-col xs:grid-flow-row sm:grid-flow-row md:grid-flow-row gap-4">
      <Banner />
      <EventSidebar />
    </div>
    <MobileReviews />
  </Layout>
)
export default IndexPage
