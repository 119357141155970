import React from "react"
import Woman from "../images/Female.jpg"
import Man from "../images/Man.jpg"
const MobileReviews = () => (
  <>
    <div class="flex flex-col flex-wrap  md:hidden lg:hidden xl:hidden">
      <blockquote class="relative bg-white rounded shadow-md max-w-screen-xl max-w-full my-4 xs:mx-4 sm:mx-4 md:mx-4">
        {" "}
        <div class="rounded px-6 py-4 sm:px-10 sm:pt-10 sm:pb-8">
          <div class="relative text-lg text-gray-700 leading-7 font-medium mt-4">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
              fill="currentColor"
              viewBox="0 0 32 32"
            >
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
            </svg>
            <p class="relative">
              As someone without much prior shooting experience, I was grateful
              to have a teacher who could explain the basics. It helped that I
              didn't have bad habits to break, and Shane offered many tips on
              how to solidify proper technique. I highly recommend him for
              beginning shooters of any age!
            </p>
          </div>
        </div>
        <cite class="flex items-center sm:items-start bg-red-500 rounded-b not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
          <div class="rounded-full border-2 border-white mr-4 sm:-mt-15 sm:mr-6">
            <img
              class="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300"
              src={Man}
              alt="woman icon"
            />
          </div>
          <span class="font-semibold leading-6">
            <strong class="text-white font-semibold">Juan T.</strong>
          </span>
        </cite>
      </blockquote>
      <blockquote class="relative bg-white rounded shadow-md max-w-screen-xl max-w-full  xs:mx-4 sm:mx-4 md:mx-4">
        <div class="rounded px-6 py-4 sm:px-10 sm:pt-10 sm:pb-8">
          <div class="relative text-lg text-gray-700 leading-7 font-medium mt-4">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-200"
              fill="currentColor"
              viewBox="0 0 32 32"
            >
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"></path>
            </svg>
            <p class="relative">
              If you are looking for an intuitive instructor who can identify
              and help you make small adjustments to improve your accuracy,
              Shane Savage is the best man for the job. I had no problem
              shooting at short distances but was less accurate further down
              range. After watching me miss my mark a few times, Shane realized
              I was cross dominant, meaning that I'm right handed but my left
              eye is strongest. He helped me change my shooting position, and I
              was on target within two shots.
            </p>
          </div>
        </div>
        <cite class="flex items-center sm:items-start bg-red-500 rounded-b not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
          <div class="rounded-full border-2 border-white mr-4 sm:-mt-15 sm:mr-6">
            <img
              class="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300"
              src={Woman}
              alt="woman icon"
            />
          </div>
          <span class="font-semibold leading-6">
            <strong class="text-white font-semibold">Ashleigh P.</strong>
          </span>
        </cite>
      </blockquote>{" "}
    </div>
  </>
)

export default MobileReviews
