import React from "react"
import SodBanner from "../images/LTC_Courses_Brazos_County.jpg"
import bop from "../images/gallery-images/p-1.jpg"
import pistolWeekend from "../images/gallery-images/p-17.jpg"
import privateInstruction from "../images/gallery-images/p-13.jpg"
import ltc from "../images/gallery-images/p-5-2.jpg"

const Banner = () => (
  <>
    <div class="lg:rounded xl:rounded max-w-full lg:ml-4 xl:ml-4 lg:mt-4 xl:mt-4 xs:w-screen sm:max-w-screen col-span-3 xs:col-span-5 sm:col-span-5 md:col-span-5">
      <img
        class="lg:rounded xl:rounded w-full shadow-md"
        src={SodBanner}
        alt="License to Carry Courses in Bryan, College Station, Texas."
      />
      <div class="lg:hidden xl:hidden rounded overflow-hidden max-w-full col-span-2 xs:col-span-5 sm:col-span-5 md:col-span-5 xs:mx-4 sm:mx-4 md:mx-4 my-4">
        <div class="flex-shrink-0">
          <img
            class="h-64 w-full object-cover"
            src={ltc}
            alt="License to Carry (LTC) course in Bryan, College Station Texas. Brazos County"
          />
        </div>
        <div class="bg-white shadow-md rounded-b py-4 px-6">
          <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
            $65/person
          </span>
          <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
            Texas LTC Course (Client Favorite)
          </h3>
          <p class="mt-3 text-base leading-6 text-gray-500">
            We offer the most inclusive Texas License to Carry course in the
            Brazos Valley and meet all state regulations to train you in
            carrying a handgun.
          </p>
          <p class="mt-3 text-base leading-6 text-gray-500">
            <strong>Women’s Class</strong>: We are proud to exclusively host the
            first women’s only LTC course in the Brazos Valley. Join us to meet
            fellow female shooters in your area!
          </p>
          <p class="mt-3 text-base leading-6 text-gray-500">
            <strong>Couples’ Class</strong>: Are you looking for something fun
            and exciting to do with your honey this weekend? Join us for our
            Couples’ LTC class!
          </p>

          <div class="mt-8 flex items-center flex-row">
            <a
              href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
              class="w-36 text-center px-3 py-2 border shadow
                    border-transparent text-base font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
              target="_blank"
              rel="noopener noreferrer"
            >
              Register
            </a>

            <a
              href="/texas-license-to-carry-ltc-course"
              class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:border-blue-300 transition duration-150 ease-in-out"
            >
              Learn more
            </a>
          </div>
        </div>
      </div>
      <div class="bg-grey-50 my-4">
        <div class="relative max-w-screen-xl mx-auto">
          <div class="mt-4 xs:mx-4 sm:mx-4 md:mx-4 grid gap-4 max-w-full xs:grid-cols-1 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-2 xl:max-w-none">
            <div class="flex bg-white flex-col rounded shadow-md overflow-hidden">
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                  src={bop}
                  alt="Basics of Pistol shooting (BOP) course in Bryan, College Station, Texas. Brazos County"
                />
              </div>
              <div class="flex-1 p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
                    $140/person
                  </span>
                  <a href="/basics-of-pistol-shooting-bop" class="block">
                    <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                      NRA’s Basics of Pistol Shooting (BOP)
                    </h3>
                  </a>
                  <p class="mt-3 text-base leading-6 text-gray-500">
                    New to handguns? Looking to increase your experience level?
                    Come check out the NRA’s BOPS course. We offer the only NRA
                    supported BOPS course in the Brazos Valley!
                  </p>
                  <p class="mt-3 text-base leading-6 text-gray-500">
                    <strong>Youth Class</strong>: Looking to teach your kids
                    proper handling of handguns? Look no further than our Youth
                    BOPS Course! This course is for those aged 13-17.
                  </p>
                </div>
                <div class="mt-8 flex">
                  <div class="inline-flex rounded-md shadow">
                    <a
                      href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                      target="_blank"
                      class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    >
                      Register now
                    </a>
                  </div>
                  <div class="ml-3 inline-flex">
                    <a
                      href="/basics-of-pistol-shooting-bop/"
                      class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col rounded shadow-md overflow-hidden">
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover  transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                  src={pistolWeekend}
                  alt="Intro to Pistols Weekend Course in Bryan, College Station, Tx. Brazos county"
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <span class="rounded text-blue-700 bg-blue-200 font-bold py-1 px-1 shadow-sm">
                    $175/person
                  </span>
                  <a href="/intro-to-pistols-weekend" class="block">
                    <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                      Intro to Pistols Weekend (Best Value)
                    </h3>
                  </a>
                  <p class="mt-3 text-base leading-6 text-gray-500">
                    Looking to expand your handgun knowledge as well as learn
                    how to defend yourself? Then look no further than our
                    all-inclusive Intro to Pistols Weekend package!
                  </p>
                </div>
                <div class="mt-8 flex">
                  <div class="inline-flex rounded-md shadow">
                    <a
                      href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                      target="_blank"
                      class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    >
                      Register now
                    </a>
                  </div>
                  <div class="ml-3 inline-flex">
                    <a
                      href="/intro-to-pistols-weekend"
                      class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="lg:col-span-2 xl:col-span-2 flex flex-col rounded shadow-md overflow-hidden">
              <div class="flex-shrink-0">
                <img
                  class="h-48 w-full object-cover  transition duration-1000 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                  src={privateInstruction}
                  alt="Private firearms instruction in Bryan, College Station, Tx. Brazos County"
                />
              </div>
              <div class="flex-1 bg-white p-6 flex flex-col justify-between">
                <div class="flex-1">
                  <a
                    href="/private-and-group-general-firearm-instruction"
                    class="block"
                  >
                    <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                      Private Instruction{" "}
                    </h3>
                  </a>
                  <p class="text-sm leading-5 font-bold">
                    <ul>
                      <li>
                        <span class="text-blue-600">$40/hr</span> (1 person)
                      </li>
                      <li>
                        <span class="text-blue-600">$60/hr</span> (2 people)
                      </li>
                      <li>
                        <span class="text-blue-600">$75/hr</span> (3 people)
                      </li>
                    </ul>
                  </p>
                  <p class="mt-3 text-base leading-6 text-gray-500">
                    For a lesson plan tailored specifically to your handgun or
                    your needs, book a private lesson today! We cover everything
                    from basics to self-defense.
                  </p>
                </div>
                <div class="mt-8 flex">
                  <div class="inline-flex rounded-md shadow">
                    <a
                      href="https://www.signupgenius.com/go/10c0d4cafa923a2f8c52-texas"
                      target="_blank"
                      class="inline-flex items-center justify-center px-3 py-1 border
                    border-transparent text-base leading-6 font-medium rounded-md
                    text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    >
                      Register now
                    </a>
                  </div>
                  <div class="ml-3 inline-flex">
                    <a
                      href="/private-and-group-general-firearm-instruction"
                      class=" px-5 py-3 border 
                    border-transparent text-base leading-6 font-medium rounded-md text-blue-700 hover:text-blue-600 hover:underline focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out"
                    >
                      Learn more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
)

export default Banner
